<script>

    // @ts-nocheck
    import { onMount } from 'svelte';  
    import { minChars } from '../helper/validate.js';
    import { settings } from '../../stores/settings.js';
    import { panels, fields } from '../../stores/form.js';
    import { user } from '../../stores/user.js';
    import { userObj } from '../../stores/userObj.js';
    import { validation } from '../../stores/validation.js';
    import ButtonOk from '../button/ButtonOk.svelte';
    import InputSelect from '../inputSelect/InputSelect.svelte';
    import InputSelectCountries from '../inputSelect/InputSelectCountries.svelte';
    import InputText from '../inputText/InputText.svelte';
    import InputTextUnknown from '../inputText/InputTextUnknown.svelte';
    import InputEmail from '../inputText/InputEmail.svelte';
    import InputDate from '../inputDate/InputDate.svelte';
    import InputStreet from '../inputStreet/InputStreet.svelte';
    import InputLocation from '../inputLocation/InputLocation.svelte';
    import Explanation from '../text/Explanation.svelte';
    import RadioButtonGroup from '../radio/RadioButtonGroup.svelte';
    import HintRadioYesNo from '../hint/HintRadioYesNo.svelte';
    import TreeNode from '../matrix/TreeNode.svelte';
    import SSNContainer from '../matrix/SSNContainer.svelte';
    import HealthInsuranceContainer from '../matrix/HealthInsuranceContainer.svelte';
    import ChildrenContainer from '../matrix/ChildrenContainer.svelte';
    import Ssn from '../matrix/SSN.svelte';
    import Footer from '../footer/Footer.svelte';
    import ExplanationDocument from '../text/ExplanationDocument.svelte';
    import TaxClassContainer from '../matrix/TaxClassContainer.svelte';
    import InputTextTree from '../inputText/InputTextTree.svelte';
    import InputTextTreeSmall from '../inputText/InputTextTreeSmall.svelte';

    export let id = "none";
    let data = $panels[id];
    let components = {};


    let inputMail, inputPwd;
    $:btnActive = true;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const preCheck = (e) => {
        e.preventDefault();
        let checkName = ( minChars(inputMail.value, 3));
        let checkPwd = ( minChars(inputPwd.value, 5));
        btnActive = (checkName && checkPwd);
    }

    const startValidation = () => {


        let panelValid = "valid";
        
        for (let [key, component] of Object.entries(components)) {
            
            let validateResult = component.validate(false);

            console.log("validateResult in panel component", key, validateResult);
            
            if (validateResult !== "valid") {
                panelValid = "invalid";
                if (validateResult === "reset") {
                    panelValid = true;
                } 
            }
        }
        return panelValid;
    }

   
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const validatePanel = () => {
        let panelValid = startValidation();
        return panelValid;
    };

    export const update = () => {
        for (let [key, component] of Object.entries(components)) {
            component.update();
        }
    }

    onMount(async () => {
        update();
	});

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>

{#if $settings.validatePanel} 
<div>
    <button on:click={startValidation}>validate</button>
</div>
{:else}
<div style="height:20px;"></div>
{/if}


<div class="daa-panel {data.panelClass}"> 

    <!-- panel head -->
    {#if data.useHead} 
        <div class="daa-panel-head">
            <div>{data.name}</div>
            {#if data.useHelp} 
                <button class="daa-hint-explain">?</button>
            {/if}
        </div>
    {/if}

    <!-- panel body -->
    <div class="daa-panel-body">

        {#each data.fields as fieldName}

        <!-- <div>want to load in panel: {fieldName}  {$fields[fieldName].type}</div> -->
        
            {#if $fields[fieldName].type === "emptyRow"}
                <div class="daa-empty-row"></div>
            {/if}

            {#if $fields[fieldName].type === "explanation"}
                <Explanation 
                    fieldName={fieldName}
                    labelClass={data.labelClass}>
                </Explanation>
            {/if}

            {#if $fields[fieldName].type === "explanationDocument"}
                <ExplanationDocument 
                    fieldName="explainSendDocument"
                    labelClass={"data.labelClass"}>
                </ExplanationDocument>
            {/if}

            {#if $fields[fieldName].type === "text"}
                <InputText bind:this={components[fieldName]} 
                    value = {userObj[fieldName]}
                    fieldName={fieldName} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </InputText>
            {/if}

            {#if $fields[fieldName].type === "textunknown"}
                <InputTextUnknown bind:this={components[fieldName]} 
                    value = {userObj[fieldName]}  
                    fieldName={fieldName} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </InputTextUnknown>
            {/if}

            {#if $fields[fieldName].type === "selection"}
                <InputSelect bind:this={components[fieldName]} 
                    value = {userObj[fieldName]}
                    fieldName={fieldName} 
                    data={$fields[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </InputSelect>
            {/if}

            {#if $fields[fieldName].type === "selectCountry"}
                <InputSelectCountries bind:this={components[fieldName]}
                    value = {userObj[fieldName]}
                    fieldName={fieldName} 
                    data={$fields[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass} 
                    show="countryName">
                </InputSelectCountries>
            {/if}

            {#if $fields[fieldName].type === "selectNationality"}
                <InputSelectCountries bind:this={components[fieldName]}
                    value = {userObj[fieldName]}
                    fieldName={fieldName} 
                    data={$fields[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass} 
                    show="nationality">
                </InputSelectCountries>
            {/if}

            {#if $fields[fieldName].type === "selectBirthCountry"}
                <InputSelectCountries bind:this={components[fieldName]} 
                    value = {userObj[fieldName]}
                    fieldName={fieldName} 
                    data={$fields[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass} 
                    show="birthCountry">
                </InputSelectCountries>
            {/if}

            {#if $fields[fieldName].type === "street"}
                <InputStreet bind:this={components[fieldName]}
                    value = {userObj[fieldName]}  
                    fieldName={fieldName} 
                    data={$fields[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </InputStreet>
            {/if}

            {#if $fields[fieldName].type === "location"}
                <InputLocation bind:this={components[fieldName]} 
                    value = {userObj[fieldName]}
                    fieldName={fieldName} 
                    data={$fields[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </InputLocation>
            {/if}

            {#if $fields[fieldName].type === "eMail"}
                <InputEmail bind:this={components[fieldName]}  
                    value = {userObj[fieldName]}
                    fieldName={fieldName} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </InputEmail>
            {/if}

            {#if $fields[fieldName].type === "date"}
                <InputDate bind:this={components[fieldName]} 
                    value = {userObj[fieldName]}  
                    fieldName={fieldName} 
                    data={$fields[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </InputDate>
            {/if}            

            {#if $fields[fieldName].type === "radio"}
                <RadioButtonGroup data={$fields[fieldName]} labelClass={data.labelClass}></RadioButtonGroup>
            {/if}

            {#if $fields[fieldName].type === "hintYesNo"}
                <HintRadioYesNo data={$fields[fieldName]} labelClass={data.labelClass}></HintRadioYesNo>
            {/if}

            {#if $fields[fieldName].type === "SSNContainer"}
                <SSNContainer bind:this={components[fieldName]} 
                    store={userObj}
                    labelClass={data.labelClass}>
                </SSNContainer>
            {/if}

            {#if $fields[fieldName].type === "healthInsuranceContainer"}
                <HealthInsuranceContainer bind:this={components[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </HealthInsuranceContainer>
            {/if}

            {#if $fields[fieldName].type === "childrenContainer"}
                <ChildrenContainer bind:this={components[fieldName]} 
                    store = {userObj}
                    labelClass={data.labelClass}>
                </ChildrenContainer>
            {/if}

            {#if $fields[fieldName].type === "tree"}
                <TreeNode bind:this={components[fieldName]}
                    fieldName={fieldName} 
                    store = {userObj}
                    linkTo="tree" 
                    labelClass={data.labelClass}>
                </TreeNode>
            {/if}

            {#if $fields[fieldName].type === "Mini"}
                <TreeNode bind:this={components[fieldName]}
                    fieldName={fieldName} 
                    store = {userObj}
                    linkTo="Mini" 
                    labelClass={data.labelClass}>
                </TreeNode>
            {/if}

            {#if $fields[fieldName].type === "AddQ"}
                <TreeNode bind:this={components[fieldName]}
                    fieldName={fieldName} 
                    store = {userObj}
                    linkTo="AddQ" 
                    labelClass={data.labelClass}>
                </TreeNode>
            {/if}

            {#if $fields[fieldName].type === "taxClassContainer"}
                <TaxClassContainer bind:this={components[fieldName]}
                    store = {userObj}
                    labelClass={data.labelClass}>
                </TaxClassContainer>
            {/if}

            {#if fieldName === "UeLeiterHoursPerWeek"}
                <InputTextTreeSmall
                    value = {userObj["UeLeiterHoursPerWeek"]} 
                    fieldName={"UeLeiterHoursPerWeek"}
                    store = {userObj}
                    labelClass="daa-label-w140 daa-force_indent"
                    numbersOnly = {true}
                    useCheckbox = {false}>
                </InputTextTreeSmall>
            {/if}

        {/each}

    </div>
</div> 



