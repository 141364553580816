<script>

    // @ts-nocheck
    import { endpoints } from '../../stores/endpoints.js';
    import { settings } from '../../stores/settings.js';
    import { minChars } from '../helper/validate.js';
    import { currentUserId } from '../../stores/user.js';
    import ButtonOk from '../button/ButtonOk.svelte';
    import ModalImpressum from '../modal/ModalImpressum.svelte';
    import ModalDisclaimer from '../modal/ModalDisclaimer.svelte';
    import Footer from '../footer/Footer.svelte';

    let inputUname, inputPwd;
    $:btnActive = false;
    $:showHintUnsuccess = false;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const preCheck = (e) => {
        e.preventDefault();
        showHintUnsuccess = false;
        let checkName = ( minChars(inputUname.value, 3));
        let checkPwd = ( minChars(inputPwd.value, 8));
        btnActive = (checkName && checkPwd);
    }
    
    const handleOkButton = () => {
        if (btnActive) {
            fetchLogin();
        }
    }

    const handleForgotPWD = () => {
        $settings.view = "forgotpwd";
    }

    ////////////////////////////////// fetch login //////////////////////////////////////////////////////////

    async function fetchLogin () {

        $settings.token = false;
        $settings.role = false;

        const response = await fetch($endpoints.EPpath+$endpoints.EPlogin, {
            method:"POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "username": inputUname.value,
                "password": inputPwd.value
            })
        });

        const resp = await response.json();

        if (resp.status === false) {
            handleUnsuccess();
        } else {
            if (resp.data.access_token) {
                $settings.token = resp.data.access_token;
                $settings.role = resp.data.role;
                $settings.groupId = resp.data.groupId;
                if (resp.data.defaultPassword) {
                    $settings.view = "finalpw";
                } else {
                    if (resp.data.role === "user") { 
                        $currentUserId.id = resp.data.id || -99999;
                        $settings.view = "welcome"; 
                    }
                    if (resp.data.role === "admin") { $settings.view = "admin"; }
                    if (resp.data.role === "superAdmin") { $settings.view = "admin"; }
                }
            }
        }
    }

    const reset = () => {
        inputUname.value = "";
        inputPwd.value = "";
        btnActive = false;
    }

    const handleUnsuccess = () => {
        reset();
        showHintUnsuccess = true;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


<div style="height:50px;"></div>

<!-- logos -->
<div class="daa-main-layout">
    <div></div>
    <div class="daa-company-logos">
        <img class="daa-company-logo" style="margin-right:10px;" src="svelte/daa_technikum_logo.png" alt="DAA Technikum Logo">
        <img class="daa-company-logo" src="svelte/daa_gmbh_logo.png" alt="DAA Gmbh Logo">
        <img class="daa-company-logo" src="svelte/hfh_logo.png" alt="HFH_logo">
        <!-- <img class="daa-company-logo" src="svelte/daa_bremen_logo.png" alt="DAA Bremen Logo"> -->
    </div>
    <div></div>
</div>

<div style="height:10px;"></div>

<!-- some text -->
<div class="daa-main-layout">

    <div></div>
    <div>
        <!-- <div class="daa-welcome-headline">Headline here</div> -->
        <div class=" daa-welcome-container">
            <div class="daa-welcome-text">Bitte geben Sie den Benutzernamen und das Startpasswort ein, das wir Ihnen zugesendet haben:</div>
        </div>
    </div>
    <div></div>
 </div>


 <div style="height:20px;"></div>


<!-- login card -->
<div class="daa-card-login">

    <div class="daa-card-login-head">Log in</div>
    <div class="daa-card-login-body">

        <!-- user name -->
        <label class="daa-login-input-label" for="inputUname">Benutzername:</label>
        <input class="daa-login-input" type="text" id="inputUname" name="inputUname" autocomplete="off"
            bind:this={inputUname}
            on:input={preCheck}
            on:change={preCheck}> 
        <div style="height: 12px;"></div>

        <!-- pwd -->
        <label class="daa-login-input-label" for="myPWD">Passwort:</label>
        <input class="daa-login-input daa-login-mask-chars" type="text" id="myPWD" name="myPWD" autocomplete="off"
            bind:this={inputPwd}
            on:input={preCheck}
            on:change={preCheck}> 

        {#if showHintUnsuccess }
            <div class="daa-login-hint-invalid">Eingaben ungültig.</div>
        {/if}

        <div style="height:40px;"></div>

        <div class="daa-buttons-row">
            <ButtonOk label="Ok" active={btnActive} callback={handleOkButton}></ButtonOk>
        </div>
    </div>

   
</div>

<div class="daa-forgot-pwd-container"><span class="daa-link-forgot-pwd" on:click={handleForgotPWD}>Passwort vergessen</span></div>


<Footer positionFix="true"></Footer>
<ModalImpressum></ModalImpressum>
<ModalDisclaimer></ModalDisclaimer>
