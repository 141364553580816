import { writable } from 'svelte/store';

export const groups = writable([
    { id: 1, entityID: "1", entityName: "DAA-Technikum" },
    { id: 2, entityID: "2", entityName: "DAA Deutsche Angestellten-Akademie GmbH" },
    { id: 4, entityID: "4", entityName: "HFH Hamburger Fern-Hochschule" },
    { id: 5, entityID: "5", entityName: "GOB" },
    { id: 6, entityID: "6", entityName: "b+r Bildung und Reisen" },
]);


export const groupsAdress = writable({

    "1_adr": {
        "name": "DAA-Technikum",
        "street": "Auf der Union 10",
        "location": "45141 Essen",
        "contact": "z.H. Frau Klahold",
        "eMail": "dozenten@daa-technikum.de"
    },

    "2_adr": {
        "name": "DAA Deutsche Angestellten-Akademie GmbH",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "information@daa.de"
    },

    "4_adr": {
        "name": "HFH Hamburger Fern-Hochschule",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "info@hfh-fernstudium.de"
    },

    "5_adr": {
        "name": "GOB",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "info(@)gob-service.net"
    },

    "6_adr": {
        "name": "b+r Bildung und Reisen",
        "street": "Alter Teichweg 17",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "info@bplusr.de"
    },
    
    "7_adr": {
        "name": "DAA HH-MV",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },
    "8_adr": {
        "name": "DAA SH",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },
    "9_adr": {
        "name": "DAA NNA",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },
    "10_adr": {
        "name": "DAA MO",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },
    "11_adr": {
        "name": "DAA NRW",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },
    "12_adr": {
        "name": "DAA RSH",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },
    "13_adr": {
        "name": "DAA BY",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },
    "14_adr": {
        "name": "DAA BaWü",
        "street": "Alter Teichweg 19",
        "location": "22081 Hamburg",
        "contact": "",
        "eMail": "theadress@daa.de"
    },

});
