<script>

     // @ts-nocheck
     
    import { onMount, onDestroy } from 'svelte';  
    import { settings } from '../../stores/settings.js';
    import { options } from '../../stores/options.js';  
    import { validateSelection } from '../helper/validate.js'; 
    import { getItemText, userText } from '../../stores/userText.js';

    export let data;
    export let fieldName = "";
    export let value ="";
    export let store;
    export let labelClass = "daa-label-w400";
    export let callback = function(val){};
 
    $:showHint = true;
    $:showWarning = false;
    $:selectOptions = $options[data.options];
    $:checkSilently = false;
    $:currentIndex = -1;
    $:currentValue = value;
    $:currentFieldText = ""; 
    let selectField;

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    const handleChange = (e) => {
        
        currentValue = selectField.value;
        currentIndex = selectField.selectedIndex;
        let selectedOption = selectField.options[currentIndex]
        currentFieldText = selectedOption.text;
        saveToStore();
        showWarning = false;
        $settings.showWarningInFooter = false;
        callback(currentValue);
    }
    
    const findOptionInSource = (str) => {
        // searches in source array, not the real options!
        for (let i = 0; i < selectOptions.length; i++) {
            let name = selectOptions[i].name;
            if (name === str) return [i, selectOptions[i]];
        }
        return [-1,[]];
    }

    const setSelectedByIndex = (arr) => {
        selectField.options.selectedIndex = arr[0];
        currentIndex = arr[0];
        currentValue = arr[1].value;
        currentFieldText = arr[1].name;
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    export function validate (boolSilent) {

        checkSilently = !boolSilent;

        let rules = data.validate;
        if (!rules) return "valid";
        if (rules.length === 0) return "valid";
        let checkSelect = validateSelection (selectField.value, rules); 
        if(checkSelect !=="valid") showWarning = true;
        return checkSelect;
    }

    export const getValue = () => {

    }

    export function getInfo() {

    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    export const update = () => {
       
        if(fieldName === "healthInsuranceCompanyPrivate") {

            let f = findOptionInSource(store["healthInsuranceCompanyPrivate"]);
            // selectField.options.selectedIndex = 3;
            setSelectedByIndex(f);
        }
        else {

            let findOptionArr = findOptionInSource (store[fieldName]);
            setSelectedByIndex(findOptionArr);
            saveToStore();
        }
    };

    const saveToStore = () => {
        
        let obj = {};
        if(!selectField.options[selectField.selectedIndex]) {
            obj[fieldName] = "";
        }
        else {
            obj[fieldName] = currentFieldText;
        }
        //optional key defined in $fields:
        if (data.keyCode && data.keyCode !== "none") {
            obj[data.keyCode] = currentValue;
        }

        Object.entries(obj).forEach(([key, value]) => {   
            store[key] = value;
        }) 

    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    onMount(async () => {  
	});

    onDestroy(() => {
        saveToStore();
	});

    const replace = (str, strReplace, replaceWith) => {
        return str.replace(strReplace, replaceWith);
    };

    // <label class="daa-input-label daa-fnt-std" for="myInput">{data.name}</label>

</script>

<div class="daa-input-grid {labelClass}">

    <div class="daa-label-container">
        <label class="daa-input-label daa-fnt-std" for="myInput">{getItemText($userText, fieldName, "name", $settings.groupId)}</label>
        {#if data.required} 
            <div class="daa-hint-asterix">*</div> 
        {/if}
    </div>
    

    <div class="daa-input-text-hint-container">

        <div class="daa-select">

            <select 
                bind:this={selectField}      
                style={(showWarning === false ) ? "": "border: 1px solid red;"}
                on:change={handleChange}>
                
                {#each selectOptions as option}
                    <option value={option.value}>{option.name}</option>
                {/each} 

            </select>
            
        </div>

        {#if data.useHelp} 
            <button class="daa-hint-explain">?</button>
        {:else}
            <div></div>
        {/if}

    </div>

</div>


<!-- Error -->
{#if showWarning}

<div class="daa-input-grid {labelClass}">
    <div></div> 
    <div class="daa-warning">{data.error}</div>
</div>

{/if}


