<script>

    import { onMount, onDestroy } from 'svelte'; 
    import { settings } from '../../stores/settings.js';    
    import { fade, fly } from 'svelte/transition';
    import { fields } from '../../stores/form.js';   
    import { user } from '../../stores/user.js'; 
    import { userObj } from '../../stores/userObj.js';
    import { validate67 } from '../helper/validate.js';
    import HelpButton from '../helpTextBox/HelpButton.svelte';
    import InputDateTree from '../inputDate/InputDateTree.svelte';
    import Check67 from '../inputDate/Check67.svelte';
    import InputTextTree from '../inputText/InputTextTree.svelte';
    import InputTextTreeSmall from '../inputText/InputTextTreeSmall.svelte';
    import InputEntryPension from '../inputDate/InputEntryPension.svelte';
    import ExplanationDocument from '../text/ExplanationDocument.svelte';
    import RadioButtonGroup from '../radio/RadioButtonGroup.svelte';
    import RadioButtonGroupTree from '../radio/RadioButtonGroupTree.svelte';
    import ExplanationTree from '../text/ExplanationTree.svelte';
    import { getItemText, userText } from '../../stores/userText.js';
   
    export let fieldName = "";  
    export let store;
    export let linkTo;  
    export let labelClass = "daa-radio-w300";
    export let count = 0;
    
    let data = $fields[fieldName];
    let inputRadio;
    let children = {};
    let inputMitgliedsnummer;
    let inputVersorgungswerk;
    let inputBefreiungsbescheidDRV
    
    $:showWarning = false;
    $:selected = "";
    $:helpCount = count+1;
    $:checkSilently = false;

    const checkIfExist = (str) => {

        let result = str.startsWith(fieldName);            
        return result
    };

    $:exists = checkIfExist(store[linkTo]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

   
    const handleChange = (e) => {

        selected =  e.target.value;
        store[linkTo] = e.target.value;
        $settings.tree = e.target.value;
       
        store[linkTo] = e.target.value;
        showWarning = false;
        $settings.showWarningInFooter = false;
    }

    const handleVersorgungswerkInput = (val) => {
        inputVersorgungswerk.saveToStore();
    }

    const handleVersorgungswerkCheckbox = (val) => {
        inputVersorgungswerk.saveToStore();
    }

    const handleMitgliednummerInput = (val) => {
        inputMitgliedsnummer.saveToStore();
    }

    const handleMitgliednummerCheckbox = (val) => {
        inputMitgliedsnummer.saveToStore();
    }

    const handleBefreiungsbescheidDRV = (val) => {
        if(val === "P_C_B_A") userObj.BefreiungsbescheidDRV = "yes";
        if(val === "P_C_B_B") userObj.BefreiungsbescheidDRV = "no";
    }

    export function validate () {
        let checkSelected = "valid";
        if(selected === "") {
            checkSelected = "invalid";
            showWarning = true;
        }

        for (let [key, child] of Object.entries(children)) {
            if (child) {
                let result = child.validate();
                // console.log("child validatation result", key, result);
            }
            
        }
        return checkSelected;
    }

    export const update = () => {
        
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    onMount(() => {
       if (inputBefreiungsbescheidDRV) inputBefreiungsbescheidDRV.update();
	});

    /////////////////////////////////////////////////////////////////////////////////////////////////////////

</script>


{#if exists} 


<div in:fly={{ y: -100, duration: 250 }} out:fade={{ duration: 20 }}>

<div class="daa-tree-radio-grid">
     
    <div></div>
    <div>
        <!-- asterix, label, helpButton -->
        <div class="daa-tree-radio-grid-head" >
        
            {#if data.required} 
            <div class="daa-hint-asterix" style="text-align:right;">*</div> 
            {:else}
                <div></div>
            {/if}

            <label class="daa-radio-label daa-radio-label-strong daa-fnt-std" for="myInput">{getItemText($userText, fieldName, "label", $settings.groupId)}</label>
            <HelpButton show={data.useHelp} ref={fieldName}></HelpButton>
        </div>

         <!--optional text -->
         {#if data.useOptionalText}
         <div class="daa-tree-radio-grid-head" >
        
            <div></div>
            <div class="daa-radio-label  daa-fnt-std">
            {getItemText($userText, fieldName, "optionalText", $settings.groupId)}  
            </div>
        </div>
        {/if}

        
        <!-- {data.optionalText}  -->

        <!------------------- subtypes --------------------------------->

        {#each data.subTypes as subType}

            {#if subType.type === "Check67"} 
                <Check67 
                    store = {userObj}
                    labelClass="">
                </Check67>
            {/if}

            {#if subType.type === "Versorgungswerk"} 
                <InputTextTree
                    bind:this={inputVersorgungswerk}
                    value = {userObj["Versorgungswerk"]} 
                    fieldName={"Versorgungswerk"}
                    name="Name d. Versorgungswerks"
                    checkBoxLabel= "Das weiß ich nicht."
                    store = {userObj}
                    labelClass="daa-label-w92"
                    callbackInput={handleVersorgungswerkInput}
                    callbackCheckbox={handleVersorgungswerkCheckbox}>
                </InputTextTree>
            {/if}

            {#if subType.type === "VWMitgliednummer"} 
                <InputTextTree
                bind:this ={inputMitgliedsnummer}
                    value = {userObj["VWMitgliednummer"]} 
                    fieldName={"VWMitgliednummer"}
                    name="Meine Mitgliedsnummer"
                    store = {userObj}
                    labelClass="daa-label-w92"
                    callbackInput={handleMitgliednummerInput}
                    callbackCheckbox={handleMitgliednummerCheckbox}>
                </InputTextTree>
            {/if}

            {#if subType.type === "explainDocs"} 
                <ExplanationDocument fieldName="explainSendDocument" labelClass="daa-label-w20"></ExplanationDocument>
            {/if}

            {#if subType.type === "explainBefreiDRV"} 
                <ExplanationTree fieldName="explainBefreiDRV" labelClass="daa-label-w20"></ExplanationTree>
            {/if}
            
            
            {#if subType.type === "InputEntryPension"} 
                <InputEntryPension store={store} labelClass="daa-label-w20"></InputEntryPension>
            {/if}

            {#if subType.type === "UeLeiterHoursPerWeek"} 
                <InputTextTreeSmall
                    value = {userObj["UeLeiterHoursPerWeek"]} 
                    fieldName={"UeLeiterHoursPerWeek"}
                    store = {userObj}
                    labelClass="daa-label-w92"
                    numbersOnly = {true}
                    useCheckbox = {false}>
                </InputTextTreeSmall>
            {/if}

            {#if subType.type === "minijobIncomeMonth"} 
                <InputTextTreeSmall
                    value = {userObj["minijobIncomeMonth"]} 
                    fieldName={"minijobIncomeMonth"}
                    store = {userObj}
                    labelClass="daa-label-w92"
                    numbersOnly = {true}
                    useCheckbox = {false}>
                </InputTextTreeSmall>
            {/if}

            {#if subType.type === "UeLeiterPauschaleMax"} 
            <InputTextTreeSmall
                value = {userObj["UeLeiterPauschaleMax"]} 
                fieldName={"UeLeiterPauschaleMax"}
                store = {userObj}
                labelClass="daa-label-w92"
                numbersOnly = {true}
                useCheckbox = {false}>
            </InputTextTreeSmall>
            {/if}

            {#if subType.type === "BefreiungsbescheidDRV_P_C_B"} 
                <RadioButtonGroupTree
                    bind:this ={inputBefreiungsbescheidDRV}
                    data={$fields["BefreiungsbescheidDRV_P_C_B"]}  
                    fieldName="BefreiungsbescheidDRV_P_C_B"
                    labelClass="daa-input-grid-tree-120"
                    checked = {userObj.BefreiungsbescheidDRV}
                    options = {$fields["BefreiungsbescheidDRV_P_C_B"].options}
                    store = {store}
                    callback={handleBefreiungsbescheidDRV}
                >
                </RadioButtonGroupTree>
            {/if}


        {/each}

         <!-------------------------------------------------------------->


    </div>

    <div class="daa-show-fieldname">{fieldName}</div>

</div>


{#if $settings.allowTreeStart || $settings.page === 5}

    <!-- radio group and error hint -->
    <div class="daa-tree-radios">

        <div></div>
        <div class="daa-radio-group-vertical ">
            <!-- {#each data.options as option} -->
            {#each getItemText($userText, fieldName, "options", $settings.groupId) as option}
            <div class="daa-radio-btn">
                <input type="radio" 
                    bind:this ={inputRadio}
                    on:change={handleChange}
                    id="{fieldName+option.value}" 
                    name="{fieldName+option.value}"  
                    value={option.value}
                    checked={store[linkTo].startsWith(option.value)}>
                <label for="{fieldName+option.value}">{option.name}</label>
            </div> 
            {/each}

            <!-- Error -->
            {#if showWarning}
                <div class="daa-warning" style="padding-left:5px;">{data.error}</div>
            {/if}

        </div>
    </div>

{/if}


<div class="daa-tree-radio-grid-separator"></div>

{#if helpCount < 10 }
    {#each data.options as option}
        {#if $fields[option.value]}
            <svelte:self 
                fieldName={option.value} 
                store={store} 
                labelClass={labelClass} 
                count={helpCount} 
                linkTo={linkTo}
                bind:this={children[option.value]}/>
        {/if}
    {/each}
{/if}

</div>

{/if}
